/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Photobook.vue?vue&type=template&id=4e75e6dd&"
import script from "./Photobook.vue?vue&type=script&lang=js&"
export * from "./Photobook.vue?vue&type=script&lang=js&"
import style0 from "./Photobook.vue?vue&type=style&index=0&id=4e75e6dd&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports