import "core-js/modules/es.array.push.js";
import { Image as VanImage, Button, Icon, Overlay, Uploader, Toast } from 'vant';
import { customizeConfig } from '../../../api/product';
import { submitCart } from '../../../api/shoppingcart';
import { post } from '../../../util/request';
import { addressDefault } from '../../../api/user';
import Vue from 'vue';
import touch from 'vue-hand-mobile';
Vue.use(touch);
import BackOff from '../../common/BackOff.vue';
import AddAddressPopup from '@/views/common/AddAddressPopup.vue';
export default {
  name: 'Photobook',
  components: {
    BackOff,
    AddAddressPopup,
    [Toast.name]: Toast,
    [Overlay.name]: Overlay,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Uploader.name]: Uploader
  },
  data() {
    return {
      uploadProcessFlag: false,
      pictureHasUploadedCount: 1,
      pictureArrayCount: Number,
      backUrl: this.$route.query.back_url,
      addAddressFlag: false,
      // 新增地址弹窗
      userDefaultAddressId: 0,
      // 用户的默认地址id
      selectedIndex: -1,
      // 更换图片时,要标记是哪个index,通过全局变量传递一下
      localStorageSelectNumber: Number,
      // 控制选择本地图片是否展示
      localStorageFlag: false,
      // 本地存储的图片url
      localStoragePictureUrls: [],
      titleTop: '0px',
      titleLeft: '0px',
      remainingPictureCount: 1,
      // 上传图片数量统计,达到最大值下面按钮会发生变化
      maxCount: Number,
      // 统计上传了多少张图
      pictureCount: 0,
      uploadFlag: true,
      // 上次移动结束之后的宽高和位置信息
      lastMoveAttr: {
        X: 0,
        // 移动过程中X，Y会发生变化,移动结束之后清空
        Y: 0,
        w: 0,
        // 移动开始的时候记录w，h的初始值，移动结束之后清空，移动过程中保持不变
        h: 0,
        scale: 0 // 移动过程中需要记录
      },

      titlePageFlag: false,
      textPage1Flag: false,
      clientScale: 0,
      // 客户端的缩放比例，客户端画布的宽 / 画布的原始宽
      clientScaleTitle: 0,
      // 客户端的封面缩放比例，客户端画布的宽 / 画布的原始宽
      fileList: [],
      // 图片上传后, 用来存储最后提交商品的值
      pictureInfoList: [],
      coverageWidthTitle: '',
      coverageHeightTitle: '',
      coverageWidth: '',
      coverageHeight: '',
      productId: this.$route.query.product_id,
      skuId: this.$route.query.sku_id,
      // productId: 1,
      // skuId: 1,
      productConfig: {},
      pictureList: [],
      cartItemId: 0,
      userAddress: {
        id: 0
      }
    };
  },
  computed: {
    maxPictureCount() {
      return this.maxCount - this.pictureCount;
    }
  },
  created: async function () {
    this.getProductConfig();
    this.getUserDefaultAddress();
  },
  methods: {
    onOversize(file) {
      // 监听复制成功事件
      Toast.setDefaultOptions({
        duration: 2000
      });
      Toast({
        message: 'The picture cannot be larger than 25M'
      });
    },
    getUserDefaultAddress() {
      addressDefault().then(res => {
        if (res.data.data.address !== undefined) {
          this.userDefaultAddressId = res.data.data.address.id;
        }
      });
    },
    // 获取产品配置,并根据长度组装数据pictureInfoList,初始位置等为0,图片为空,最后传给后端去生成订单
    getProductConfig() {
      const params = {
        productId: this.productId,
        skuId: this.skuId
      };
      customizeConfig(params).then(res => {
        this.productConfig = res.data.data;
        this.pictureList = res.data.data.customizeInfo.list;
        this.coverageWidth = '45vw';
        // eslint-disable-next-line no-eval
        this.coverageHeight = eval(45 / res.data.data.customizeInfo.list[1].width * res.data.data.customizeInfo.list[1].height) + 'vw';
        this.maxCount = res.data.data.customizeInfo.list.length;
        this.remainingPictureCount = res.data.data.customizeInfo.list.length;
        // 生成列表,存储图片和位置信息

        for (let index = 0; index < this.maxCount; index++) {
          const customize = {
            'userImgInfo': [{
              'w': '0',
              'h': '0',
              'x': '',
              'y': '',
              'width': '0px',
              'height': '0px',
              'top': '0px',
              'left': '0px',
              'photoInfo': {
                'url': '',
                'width': 0,
                'height': 0
              }
            }],
            'coverImgs': [{
              'bgImg': '',
              'width': 0,
              'height': 0,
              'coverImg': '',
              'cusArea': {
                'x': '0',
                'y': '0',
                'w': '0',
                'h': '0'
              }
            }],
            'bgImages': [{
              'url': '',
              'w': '0',
              'h': '0',
              'x': '0',
              'y': '0',
              'rotate': '0',
              'width': 0,
              'height': 0
            }]
          };
          this.pictureInfoList.push(customize);
        }
        // 封面的背景图片处理
        this.pictureInfoList[0].coverImgs[0].url = this.pictureList[0].coverImg;
        this.pictureInfoList[0].coverImgs[0].width = this.pictureList[0].width;
        this.pictureInfoList[0].coverImgs[0].height = this.pictureList[0].height;
        this.pictureInfoList[0].coverImgs[0].cusArea.x = this.pictureList[0].cusArea.x;
        this.pictureInfoList[0].coverImgs[0].cusArea.y = this.pictureList[0].cusArea.y;
        this.pictureInfoList[0].coverImgs[0].cusArea.w = this.pictureList[0].cusArea.w;
        this.pictureInfoList[0].coverImgs[0].cusArea.h = this.pictureList[0].cusArea.h;
        this.pictureInfoList[0].bgImages[0].url = this.pictureList[0].coverImg;
        this.pictureInfoList[0].bgImages[0].width = this.pictureList[0].width;
        this.pictureInfoList[0].bgImages[0].height = this.pictureList[0].height;
        this.pictureInfoList[0].bgImages[0].w = this.pictureList[0].cusArea.w;
        this.pictureInfoList[0].bgImages[0].h = this.pictureList[0].cusArea.h;
        this.pictureInfoList[0].bgImages[0].x = this.pictureList[0].cusArea.x;
        this.pictureInfoList[0].bgImages[0].y = this.pictureList[0].cusArea.y;
        this.coverageWidthTitle = this.pictureList[0].cusArea.w + 'px';
        this.coverageHeightTitle = this.pictureList[0].cusArea.h + 'px';
        // 正文处理
        for (let index = 1; index < this.maxCount; index++) {
          this.pictureInfoList[index].coverImgs[0].width = this.pictureList[index].width;
          this.pictureInfoList[index].coverImgs[0].height = this.pictureList[index].height;
          this.pictureInfoList[index].coverImgs[0].cusArea.w = this.pictureList[index].cusArea.w;
          this.pictureInfoList[index].coverImgs[0].cusArea.h = this.pictureList[index].cusArea.h;
          this.pictureInfoList[index].bgImages[0].width = this.pictureList[index].width;
          this.pictureInfoList[index].bgImages[0].height = this.pictureList[index].height;
          this.pictureInfoList[index].bgImages[0].w = this.pictureList[index].cusArea.w;
          this.pictureInfoList[index].bgImages[0].h = this.pictureList[index].cusArea.h;
        }
        const localPictureInfoList = JSON.parse(localStorage.getItem('localPictureInfoStorage' + this.skuId));
        if (localPictureInfoList) {
          this.pictureInfoList = localPictureInfoList;
          for (let index = 0; index < this.pictureInfoList.length; index++) {
            // eslint-disable-next-line eqeqeq
            if (this.pictureInfoList[index].userImgInfo[0].photoInfo.url != '') {
              this.remainingPictureCount--;
            }
          }
          // 判断最下方展示上传图片还是提交订单
          for (let index = 0; index < this.pictureInfoList.length; index++) {
            // eslint-disable-next-line eqeqeq
            if (this.pictureInfoList[index].userImgInfo[0].photoInfo.url == '') {
              break;
            }
            // eslint-disable-next-line eqeqeq
            if (index == this.pictureInfoList.length - 1) {
              this.uploadFlag = false;
            }
          }
        }
        // const localStoragePictureUrls = JSON.parse(localStorage.getItem('localStoragePictureUrls'))
        // if (localStoragePictureUrls) {
        //   this.localStoragePictureUrls = localPictureInfoList
        // }

        const screenWidth = window.innerWidth;
        // 获取手机上和实际的比例,用作缩放
        // 正文比例
        this.clientScale = screenWidth * 0.45 / this.pictureList[1].width;
        // 封面比例
        this.clientScaleTitle = screenWidth * 0.45 / this.pictureList[0].width;
        // 算出来封面定制区域位置,在html中使用
        this.titleTop = this.pictureList[0].cusArea.y * this.clientScaleTitle + 'px';
        // 算出来封面定制区域位置,在html中使用
        this.titleLeft = this.pictureList[0].cusArea.x * this.clientScaleTitle + 'px';
        // 封面定制区域的大小
        this.coverageWidthTitle = this.pictureList[0].cusArea.w * this.clientScaleTitle + 'px';
        this.coverageHeightTitle = this.pictureList[0].cusArea.h * this.clientScaleTitle + 'px';
      });
    },
    // 从底部上传图片,分为多张和单张处理
    afterRead(fileList) {
      // 一次性上传多张
      if (fileList instanceof Array) {
        // 显示上传进度
        this.uploadProcessFlag = true;
        this.pictureArrayCount = fileList.length;
        for (let index = 0; index < fileList.length; index++) {
          const imgFile = new FormData();
          imgFile.append('fileType', 'IMAGE');
          imgFile.append('file', fileList[index].file);
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          };
          post('/common/img/upload', imgFile, config).then(res => {
            // 上传进度消失
            this.pictureHasUploadedCount++;
            if (this.pictureHasUploadedCount >= this.pictureArrayCount) {
              this.uploadProcessFlag = false;
              this.pictureHasUploadedCount = 1;
            }
            this.remainingPictureCount--;
            this.localStoragePictureUrls.push(res.data.data);
            localStorage.setItem('localStoragePictureUrls', this.localStoragePictureUrls);
            this.singlePictureAction(res);
          });
        }
      } else {
        const imgFile = new FormData();
        imgFile.append('fileType', 'IMAGE');
        imgFile.append('file', fileList.file);
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        post('/common/img/upload', imgFile, config).then(res => {
          this.remainingPictureCount--;
          this.localStoragePictureUrls.push(res.data.data);
          localStorage.setItem('localStoragePictureUrls', this.localStoragePictureUrls);
          this.singlePictureAction(res);
        });
      }
    },
    // 单个图片调接口后的处理
    singlePictureAction(res) {
      const userPictureWidth = res.data.data.width;
      const userPictureHeight = res.data.data.height;
      let pictureNum = -1;
      // 循环图片信息列表, 图片为空则放到该地方
      for (let index = 0; index < this.pictureInfoList.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (this.pictureInfoList[index].userImgInfo[0].photoInfo.url == '') {
          this.pictureInfoList[index].userImgInfo[0].photoInfo.url = res.data.data.src;
          pictureNum = index;
          break;
        }
      }
      // 判断最下方展示上传图片还是提交订单
      for (let index = 0; index < this.pictureInfoList.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (this.pictureInfoList[index].userImgInfo[0].photoInfo.url == '') {
          break;
        }
        // eslint-disable-next-line eqeqeq
        if (index == this.pictureInfoList.length - 1) {
          this.uploadFlag = false;
        }
      }
      // 封面图片处理
      // eslint-disable-next-line eqeqeq
      if (pictureNum == 0) {
        this.putTextPicture(userPictureWidth, userPictureHeight, this.clientScaleTitle, pictureNum);
      } else {
        // 正文图片处理
        this.putTextPicture(userPictureWidth, userPictureHeight, this.clientScale, pictureNum);
      }
    },
    // 放置图片
    putTextPicture(userImageWidth, userImageHeight, scaleRate, pictureIndex) {
      // 更改图片后位置复原
      this.pictureInfoList[pictureIndex].userImgInfo[0].top = '0px';
      this.pictureInfoList[pictureIndex].userImgInfo[0].left = '0px';
      userImageWidth = userImageWidth * scaleRate;
      userImageHeight = userImageHeight * scaleRate;
      // 定制区域
      const parent = document.getElementById('custom-user-image-option-box-' + pictureIndex + '-area').getBoundingClientRect();
      const parentRate = parent.width / parent.height;
      const userImgRate = userImageWidth / userImageHeight;
      if (parentRate > userImgRate) {
        this.pictureInfoList[pictureIndex].userImgInfo[0].photoInfo.width = parent.width + 'px';
        this.pictureInfoList[pictureIndex].userImgInfo[0].photoInfo.height = parent.width / userImageWidth * userImageHeight + 'px';
      } else {
        this.pictureInfoList[pictureIndex].userImgInfo[0].photoInfo.height = parent.height + 'px';
        this.pictureInfoList[pictureIndex].userImgInfo[0].photoInfo.width = parent.height / userImageHeight * userImageWidth + 'px';
      }

      // 放在左上角
      // this.userImgX = -2 + 'px';
      // this.userImgY = -2 + 'px';
      this.userImgShow = true; // 用户图片层显示
      this.imgUploadingShow = false; // loading消失
      this.operateBorderShow = true; // 操作图层边框显示v
      this.operateBorder = '1px dashed #ff0000';
      localStorage.setItem('localPictureInfoStorage' + this.skuId, JSON.stringify(this.pictureInfoList));
      return true;
    },
    postToShoppingCart() {
      for (var i = 0; i < this.maxCount; i++) {
        this.calImgPosition(i);
      }
      // eslint-disable-next-line eqeqeq
      if (this.userDefaultAddressId != 0) {
        this.postOrderLoadingShow = true;
        const submitData = {
          productId: this.productId,
          skuId: this.skuId,
          count: 1,
          detail: {
            customize: this.pictureInfoList
          }
        };
        submitCart(submitData).then(res => {
          // @todo 如果用户提交之后刷新页面，数据会丢失
          this.cartItemId = res.data.data.id;
          // 用户有默认地址，可以直接去结算页面
          this.goToBill();
        });
      } else {
        this.popupAddAddress();
      }
    },
    goToBill() {
      this.$router.push({
        path: '/shoppingcart/bill',
        query: {
          cartItemIds: [this.cartItemId],
          userAddressId: this.userDefaultAddressId,
          skuId: this.skuId,
          productId: this.productId,
          back_url: '/editor/photo_book?product_id=' + this.productId + '&sku_id=' + this.skuId
        }
      });
    },
    selectPicture(index) {
      this.addAddressFlag = false;
      this.selectedIndex = index;
      for (let num = 0; num < this.maxCount; num++) {
        const myDiv = document.getElementById('custom-user-image-option-box-' + num);

        // eslint-disable-next-line eqeqeq
        if (index == num) {
          // myDiv.setAttribute('z-index', 10)
          myDiv.style.zIndex = 10;
        } else {
          if (myDiv) {
            myDiv.style.zIndex = -1;
          }
          // myDiv.setAttribute('z-index', -1)
        }
      }
    },

    doAction(eventObject) {
      let test = document.getElementById(eventObject.target._prevClass.split(' ')[1]).getBoundingClientRect();
      if (eventObject.type === 'tap') {
        // console.log('eventObject.target = ' + eventObject.target)
        // console.log(eventObject.target)
        // console.log(eventObject.target.id)
        // console.log(eventObject.target._prevClass.split(' ')[1])
        // console.log('tap')
      }
      if (eventObject.type === 'pinch') {
        // console.log('eventObject.target = ' + eventObject.target)
        // console.log(eventObject.target._prevClass)
        // console.log(eventObject.target._prevClass.split(' ')[1])
        // console.log('pinch')
        // this.canMovePicture = !this.canMovePicture
      }
      const index = parseInt(eventObject.target._prevClass.split(' ')[1].split('-')[5]);
      // eslint-disable-next-line eqeqeq
      if (this.selectedIndex == index) {
        const parent = document.getElementById(eventObject.target._prevClass.split(' ')[1] + '-area').getBoundingClientRect();

        // this.pictureInfoList[index].userImgInfo[0].photoInfo.x = (userImgRect.x - 0 - cusAreaRect.x) * rate
        if (eventObject.eventType === 'end') {
          // 移动结束之后，需要将操作记录清空，下次移动重新计算
          this.lastMoveAttr.X = 0;
          this.lastMoveAttr.Y = 0;
          this.lastMoveAttr.w = 0;
          this.lastMoveAttr.h = 0;
          this.lastMoveAttr.scale = 0;
          // 如果超出了边界，需要处理
          if (parent.width > test.width) {
            // 等比缩放
            this.pictureInfoList[index].userImgInfo[0].photoInfo.width = parent.width + 'px';
            this.pictureInfoList[index].userImgInfo[0].photoInfo.height = parent.width / test.width * test.height + 'px';
          }
          if (parent.height > test.height) {
            // 等比缩放
            this.pictureInfoList[index].userImgInfo[0].photoInfo.height = parent.height + 'px';
            this.pictureInfoList[index].userImgInfo[0].photoInfo.width = parent.height / test.height * test.width + 'px';
          }
          // 更新最新的位置信息
          test = document.getElementById(eventObject.target._prevClass.split(' ')[1]).getBoundingClientRect();
          if (parent.top < test.top) {
            // console.log('parent.top < test.top')
            this.pictureInfoList[index].userImgInfo[0].top = 0 + 'px';
          }
          if (parent.left < test.left) {
            // console.log('parent.left < test.left')
            this.pictureInfoList[index].userImgInfo[0].left = 0 + 'px';
          }
          if (parent.right > test.right) {
            // console.log('parent.right > test.right')
            const currentDivL = parseInt(this.pictureInfoList[index].userImgInfo[0].left, 10);
            this.pictureInfoList[index].userImgInfo[0].left = currentDivL + parent.right - test.right + 'px';
          }
          if (parent.bottom > test.bottom) {
            // console.log('parent.bottom > test.bottom')
            const currentDivT = parseInt(this.pictureInfoList[index].userImgInfo[0].top, 10);
            this.pictureInfoList[index].userImgInfo[0].top = currentDivT + parent.bottom - test.bottom + 'px';
          }
        } else {
          // 移动过程中本次数据 与 场次数据的坐标差值，
          const diffX = eventObject.X - this.lastMoveAttr.X;
          const diffY = eventObject.Y - this.lastMoveAttr.Y;

          // 当前元素的位置(相对父级元素) + 本次移动过程中的增量 （-1为 父级div的边框大小,已经干掉了）
          this.pictureInfoList[index].userImgInfo[0].left = test.left - parent.left + diffX + 'px';
          this.pictureInfoList[index].userImgInfo[0].top = test.top - parent.top + diffY + 'px';

          // 如果当前的scale与上次一样，不做处理
          if (eventObject.scale !== undefined && eventObject.scale !== this.lastMoveAttr.scale) {
            // 初始化当前
            if (this.lastMoveAttr.w === 0) {
              this.lastMoveAttr.w = test.width;
              this.lastMoveAttr.h = test.height;
            }

            // diffW = 移动开始时候元素的宽高 * (scale - 1)
            const diffW = this.lastMoveAttr.w * (eventObject.scale - 1) / 100;
            const diffH = this.lastMoveAttr.h * (eventObject.scale - 1) / 100;
            this.pictureInfoList[index].userImgInfo[0].photoInfo.width = test.width + diffW + 'px';
            this.pictureInfoList[index].userImgInfo[0].photoInfo.height = test.height + diffH + 'px';
          }

          // 记录下本次操作的坐标，作为下次操作的差值基点
          this.lastMoveAttr.X = eventObject.X;
          this.lastMoveAttr.Y = eventObject.Y;
          this.lastMoveAttr.scale = eventObject.scale;
        }
        this.calImgPosition(index);
      }
    },
    calImgPosition(content) {
      const userImgRect = document.getElementById('custom-user-image-option-box-' + content).getBoundingClientRect();
      // 定制区域的位置
      const cusAreaRect = document.getElementById('custom-user-image-option-box-' + content + '-area').getBoundingClientRect();
      const rate = this.pictureList[1].cusArea.w / cusAreaRect.width;
      // const index = parseInt(content.split('-')[5])
      this.pictureInfoList[content].userImgInfo[0].x = (userImgRect.x - 0 - cusAreaRect.x) * rate;
      this.pictureInfoList[content].userImgInfo[0].y = (userImgRect.y - 0 - cusAreaRect.y) * rate;
      this.pictureInfoList[content].userImgInfo[0].w = userImgRect.width * rate;
      this.pictureInfoList[content].userImgInfo[0].h = userImgRect.height * rate;
      localStorage.setItem('localPictureInfoStorage' + this.skuId, JSON.stringify(this.pictureInfoList));
    },
    onClickLeft() {
      this.$router.push({
        path: '/editor/detail',
        query: {
          productId: this.productId
        }
      });
    },
    createAfterReadHandler(index) {
      // this.selectedIndex = -1
      // 创建一个回调函数并绑定索引值作为参数
      return file => {
        this.handleUploaderAfterRead(file, index);
      };
    },
    handleUploaderAfterRead(file, index) {
      // 在这里处理上传完成事件
      // console.log(`Uploader at index ${index} uploaded file "${file.name}".`)
      const imgFile = new FormData();
      imgFile.append('fileType', 'IMAGE');
      imgFile.append('file', file.file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      post('/common/img/upload', imgFile, config).then(res => {
        this.selectedIndex = -1;
        this.remainingPictureCount--;
        this.localStoragePictureUrls.push(res.data.data);
        localStorage.setItem('localStoragePictureUrls', this.localStoragePictureUrls);
        const userPictureWidth = res.data.data.width;
        const userPictureHeight = res.data.data.height;
        this.pictureInfoList[index].userImgInfo[0].photoInfo.url = res.data.data.src;
        // 判断最下方展示上传图片还是提交订单
        for (let index = 0; index < this.pictureInfoList.length; index++) {
          // eslint-disable-next-line eqeqeq
          if (this.pictureInfoList[index].userImgInfo[0].photoInfo.url == '') {
            break;
          }
          // eslint-disable-next-line eqeqeq
          if (index == this.pictureInfoList.length - 1) {
            this.uploadFlag = false;
          }
        }

        // 封面图片处理
        // eslint-disable-next-line eqeqeq
        if (index == 0) {
          this.putTextPicture(userPictureWidth, userPictureHeight, this.clientScaleTitle, index);
        } else {
          // 正文图片处理
          this.putTextPicture(userPictureWidth, userPictureHeight, this.clientScale, index);
        }
      });
    },
    // 上传图片之后,点击chang的动作调用方法
    localStorageSelect(index) {
      this.localStorageSelectNumber = index;
      this.localStorageFlag = true;
    },
    selectLocalStoragePicture(item) {
      this.localStorageFlag = false;
      this.pictureInfoList[this.localStorageSelectNumber].userImgInfo[0].photoInfo.url = item.src;
      // 封面图片处理
      // eslint-disable-next-line eqeqeq
      if (this.localStorageSelectNumber == 0) {
        this.putTextPicture(item.width, item.height, this.clientScaleTitle, this.localStorageSelectNumber);
      } else {
        // 正文图片处理
        this.putTextPicture(item.width, item.height, this.clientScale, this.localStorageSelectNumber);
      }
    },
    singePictureUpload(file) {
      this.localStorageFlag = false;
      const imgFile = new FormData();
      imgFile.append('fileType', 'IMAGE');
      imgFile.append('file', file.file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      post('/common/img/upload', imgFile, config).then(res => {
        this.localStoragePictureUrls.push(res.data.data);
        localStorage.setItem('localStoragePictureUrls', this.localStoragePictureUrls);
        const userPictureWidth = res.data.data.width;
        const userPictureHeight = res.data.data.height;
        this.pictureInfoList[this.localStorageSelectNumber].userImgInfo[0].photoInfo.url = res.data.data.src;
        // 封面图片处理
        // eslint-disable-next-line eqeqeq
        if (this.localStorageSelectNumber == 0) {
          this.putTextPicture(userPictureWidth, userPictureHeight, this.clientScaleTitle, this.localStorageSelectNumber);
        } else {
          // 正文图片处理
          this.putTextPicture(userPictureWidth, userPictureHeight, this.clientScale, this.localStorageSelectNumber);
        }
      });
    },
    closeLocalStorageFlag() {
      this.localStorageFlag = false;
    },
    // 添加地址弹窗
    popupAddAddress() {
      this.addAddressFlag = false;
      this.addAddressFlag = true;
    },
    callbackForAdd(callbackData) {
      this.addAddressFlag = false;
      this.userDefaultAddressId = callbackData.addressId;
      this.postToShoppingCart();
      // this.getAddressList();
    }
  }
};